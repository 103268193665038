<template>
  <div class="bundle-styling-accordion">
    <div class="bundle-styling-accordion-title d-flex align-items-center" :class="{'active' : localActive}" @click="setPanel">
      <slot name="title">{{ localTitle }}</slot>
      <span class="chevron-icon-container ml-1">
        <fawesome-pro variant="far" icon="chevron-down" class="chevron-icon" :class="{'active' : localActive}" size="md" />
      </span>
    </div>
    <div class="bundle-styling-accordion-body">
      <slot><div v-html="localBody"></div></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Accordion",
  props: ["title", "body", "active"],
  data() {
    return {
      localTitle: this.title,
      localBody: this.body,
      localActive: this.active ?? false,
    }
  },
  methods: {
    setPanel(event) {
      let panel = event.target.nextElementSibling
      if (!panel) panel = event.target.parentElement
      panel.style.setProperty('--scrollHeight', panel.scrollHeight + "px")
      this.localActive = !this.localActive
    }
  },
  watch: {
    title(newVal) {
      this.localTitle = newVal
    },
    localBody(newVal) {
      this.localBody = newVal
    }
  }
}
</script>
<style scoped>
.bundle-styling-accordion .bundle-styling-accordion-title {
  position: relative;
  cursor: pointer;
  font-size: 14px;

  /* New */
  width: fit-content;
}
/* OLD chevron icon styling (chevron icon sometime not centered) */
/* .bundle-styling-accordion .bundle-styling-accordion-title:after { */
  /* Old */
  /* font-size: .8em;
  position: absolute;
  height: fit-content;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  content: "\f078";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  transition: all .3s linear; */

  /* New */
   /* font-size: .8em;
  position: absolute;
  height: fit-content;
  right: -25px;
  top: 0;
  bottom: 0;
  margin: auto;
  content: "\f078";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  transition: all .3s linear;
	border-radius: 50%;
  background: #d89700;
  color: #ffffff;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center; */
/* } */
.bundle-styling-accordion .bundle-styling-accordion-title.active:after {
  transform: rotate(-180deg);
}
/* NEW chevron icon styling (centered chevron icon) */
.chevron-icon-container {
  width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
  border-radius: 50%;
  background: #d89700;
  color: #ffffff;
  font-size: .8em;
}
.chevron-icon-container .chevron-icon.active {
  transform: rotate(-180deg);
}

.bundle-styling-accordion .bundle-styling-accordion-title + .bundle-styling-accordion-body {
  font-size: 13px;
  color: #828282;
  max-height: 0;
  overflow: hidden;
  visibility: collapse;
  transition: all .3s linear;
  opacity: 0;
}
.bundle-styling-accordion .bundle-styling-accordion-title.active + .bundle-styling-accordion-body {
  max-height: var(--scrollHeight, 100%);
  visibility: visible;
  overflow: visible;
  opacity: 1;
}
</style>
